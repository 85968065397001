<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-form inline>
            <b-form-group>
              <b-form-checkbox
                v-model="compactMode"
              >
                Compact mode
              </b-form-checkbox>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.dataUsagePercentageChartOptions"
        :series="[dataUsagePercentageSeries, dataUsagePercentageTrendBase90DaysSeries, dataUsagePercentageTrendBase365DaysSeries]"
      />
    </b-card>

    <b-card>
      <apexchart
        type="line"
        :options="chartOptions.longPeriodForecastChartOptions"
        :series="[dataUsagePercentageSeries, dataUsagePercentageTrendBase5YearsSeries]"
      />
    </b-card>

  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BFormCheckbox, VBPopover, BForm, BFormGroup,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import DataDomainService from '@/service/datadomain.service'
import moment from '@/libs/moment'
import calculation from '@/utils/calculation'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    apexchart: VueApexCharts,
    BFormCheckbox,
    BForm,
    BFormGroup,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    asup: {
      type: Object,
      default: () => {},
    },
    asset: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      compactMode: false,
      chartHeightNormalMode: 350,
      chartHeightCompactMode: 150,
      statistic: [],
      chartOptions: {
        dataUsagePercentageChartOptions: {
          chart: {
            id: 'dataUsagePercentageChart',
            group: 'capacity',
            type: 'line',
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Data usage forecast',
            align: 'left',
          },
          yaxis: [
            {
              seriesName: 'Data usage',
              labels: {
                formatter(val) {
                  return `${(val).toFixed(0)}%`
                },
              },
              title: {
                text: 'Percentage %',
              },
              min: 0,
              max: 100,
              minWidth: 0,
            },
          ],
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              // eslint-disable-next-line no-unused-vars
              formatter(val, config) {
                if (val) {
                  return `${(val).toFixed(0)}%`
                }

                return null
              },
            },
            x: {
              formatter(val) {
                return moment(val).format('L')
              },
            },
          },
        },
        longPeriodForecastChartOptions: {
          chart: {
            id: 'longPeriodForecastChart',
            group: 'capacity',
            type: 'line',
            height: 350,
            zoom: {
              autoScaleYaxis: true,
            },
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          stroke: {
            show: true,
            curve: 'straight',
            lineCap: 'butt',
            width: 1.5,
          },
          dataLabels: {
            enabled: false,
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Data usage forecast (long period)',
            align: 'left',
          },
          yaxis: [
            {
              seriesName: 'Data usage',
              labels: {
                formatter(val) {
                  return `${(val).toFixed(0)}%`
                },
              },
              title: {
                text: 'Percentage %',
              },
              min: 0,
              max: 100,
              minWidth: 0,
            },
          ],
          xaxis: {
            type: 'datetime',
          },
          tooltip: {
            shared: true,
            y: {
              // eslint-disable-next-line no-unused-vars
              formatter(val, config) {
                if (val) {
                  return `${(val).toFixed(0)}%`
                }

                return null
              },
            },
            x: {
              formatter(val) {
                return moment.utc(val).format('L')
              },
            },
          },
        },
      },
    }
  },
  computed: {
    chartIds() {
      const chartIds = []
      // eslint-disable-next-line no-unused-vars
      Object.entries(this.chartOptions).forEach(([key, chartOption]) => {
        chartIds.push(chartOption.chart.id)
      })

      return chartIds
    },
    chartHeight() {
      if (this.compactMode === true) {
        return this.chartHeightCompactMode
      }

      return this.chartHeightNormalMode
    },
    dataUsagePercentageSeries() {
      return {
        name: 'Data usage',
        data: this.statistic.map(x => [x.timestamp, x.usedInPercent]),
      }
    },
    dataUsagePercentageTrendBase365DaysSeries() {
      const statisticData = this.statistic.filter(x => moment(x.timestamp) > moment().subtract(365, 'days'))
      const seriesData = this.calculateForecastChartSeriesData(statisticData, 730)

      return {
        name: 'Trend (Base 365 days)',
        data: seriesData,
      }
    },
    dataUsagePercentageTrendBase90DaysSeries() {
      const statisticData = this.statistic.filter(x => moment(x.timestamp) > moment().subtract(90, 'days'))
      const seriesData = this.calculateForecastChartSeriesData(statisticData, 730)

      return {
        name: 'Trend (Base 90 days)',
        data: seriesData,
      }
    },
    dataUsagePercentageTrendBase5YearsSeries() {
      const statisticData = this.statistic.filter(x => moment(x.timestamp) > moment().subtract(5, 'years'))
      const seriesData = this.calculateForecastChartSeriesData(statisticData, 730)

      return {
        name: 'Trend (Base 5 years)',
        data: seriesData,
      }
    },
  },
  watch: {
    compactMode(newCompactMode) {
      for (let i = 0; i < this.chartIds.length; i += 1) {
        const { opts } = this.$apexcharts.getChartByID(this.chartIds[i])

        // Update height
        opts.chart = this.$apexcharts.merge(opts, {
          height: this.chartHeight,
        })

        for (let j = 0; j < opts.yaxis.length; j += 1) {
        // Update all Y axis tick amount to prevent overlapping labels
          opts.yaxis[j] = this.$apexcharts.merge(opts.yaxis[j], {
            tickAmount: newCompactMode === true ? 2 : 10,
            title: {
              style: {
                fontSize: newCompactMode === true ? '8px' : '12px',
              },
            },
          })
        }

        this.$apexcharts.exec(this.chartIds[i], 'updateOptions', opts)
      }
    },
  },
  created() {
    this.loadStatistic()
  },
  mounted() {
    for (let i = 0; i < this.chartIds.length; i += 1) {
      const { opts } = this.$apexcharts.getChartByID(this.chartIds[i])
      // Update height
      opts.chart = this.$apexcharts.merge(opts, {
        height: this.chartHeight,
      })

      if (this.chartIds[i] === 'dataUsagePercentageChart') {
        // Update X axis with new time range
        opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
          min: moment.utc().subtract(365, 'days').startOf('day').valueOf(),
          max: moment.utc().add(730, 'days').endOf('day').valueOf(),
        })
      }
      if (this.chartIds[i] === 'longPeriodForecastChart') {
        // Update X axis with new time range
        opts.xaxis = this.$apexcharts.merge(opts.xaxis, {
          min: moment.utc().subtract(5, 'years').startOf('day').valueOf(),
          max: moment.utc().add(730, 'days').endOf('day').valueOf(),
        })
      }

      this.$apexcharts.exec(this.chartIds[i], 'updateOptions', opts)
    }
  },
  methods: {
    loadStatistic() {
      const includeFields = [
        'UsedInPercent',
      ]
      /*
        Performance data is stored in child list of statistic data. Performance data of e.g. yesterday may be contained in statistics of today.
        So we need to query statistic data one day before and after the range
        */
      DataDomainService.getAllStatisticListAsync(this.asup.id, {
        from: moment().subtract(5, 'years').startOf('day').toISOString(),
        to: moment().add(1, 'days').endOf('day').toISOString(),
        include: includeFields,
        maxResultCount: 1000,
      }, { disableTenantFilter: true })
        .then(result => {
          this.statistic = result.filter(x => x.usedInPercent !== null)
        })
    },
    calculateForecastChartSeriesData(data, forecastDays) {
      if (!data || data.length === 0) {
        return []
      }

      // make sure we have one datapoint for each day (use max. usedInPercent if multiple datapoints exists for one day)
      // eslint-disable-next-line no-unused-vars
      const groupByDay = data.reduce((p, c) => {
        // remove invalid values
        if (!c.usedInPercent) {
          return p
        }

        const timestampKey = moment(c.timestamp).format('YYYY-MM-DD')
        // eslint-disable-next-line no-param-reassign
        p[timestampKey] = p[timestampKey] || []
        p[timestampKey].push(c)
        return p
      }, {})

      const filteredData = []
      // eslint-disable-next-line guard-for-in, no-restricted-syntax, no-unused-vars
      for (const day in groupByDay) {
        const maxUsedInPercent = Math.max(...groupByDay[day].map(x => x.usedInPercent))

        filteredData.push({
          timestamp: groupByDay[day].find(x => x.usedInPercent === maxUsedInPercent).timestamp,
          usedInPercent: maxUsedInPercent,
        })
      }

      const xdata = filteredData.map((x, index) => index)
      const ydata = filteredData.map(x => x.usedInPercent)

      // eslint-disable-next-line no-unused-vars
      const resultRegress = calculation.regress(xdata, ydata)

      // eslint-disable-next-line no-unused-vars
      const last = filteredData[filteredData.length - 1]

      const forecastDate = this.adjustByTimezone(this.$moment.utc(last.timestamp)).add(forecastDays, 'days').toDate()
      const forecastValue = resultRegress.slope * (xdata[xdata.length - 1] + forecastDays) + resultRegress.intercept

      return [[this.adjustByTimezone(this.$moment.utc(filteredData[0].timestamp)), resultRegress.intercept], [forecastDate, forecastValue]]
    },
    adjustByTimezone(dateTime) {
      const offset = this.$moment.tz.zone(this.asup.timezone).utcOffset(dateTime)
      return dateTime.clone().subtract(offset, 'minutes')
    },
  },
}

</script>

<style scoped>
  .form-inline {
    place-items: flex-start
  }

  .form-group {
    margin-right: 15px;
  }
</style>
